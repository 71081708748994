<template>
    <div>
        <hero/>
        <contents :y="athletes.youth"/>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        components: {
            Hero: () => import ('@/components/teams/Hero'),
            Contents: () => import('@/components/teams/Contents')
        },
        computed: {
            ...mapState('app',['athletes'])
        },
        mounted() {
            this.$store.dispatch('app/setLink', 3)
        }
    }
</script>
